import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { addToViewedProductsAsync } from "../features/products/productSlice";

function countWords(text) {
  return text.split(/\s+/).filter(word => word !== '').length;
}

const Productcard2 = (props) => {
  const { grid, data } = props;
  let location = useLocation();
  const authState = useSelector(state => state?.auth)

  const dispatch = useDispatch();

  const getCustomerfromLocalStorage = localStorage.getItem("customer")
  ? JSON.parse(localStorage.getItem("customer"))
  : null;

  const user = authState?.user?.user ? authState?.user?.user : authState?.user;
  const userName = user?.firstname || "";
  const userId = user?._id || "";


  const handleProductView = async (productId) => {
    try {
      await dispatch(addToViewedProductsAsync({ id: userId, productId }));
  
      // Navigate to the product details page
  
      // Update the local storage with the recently viewed products
      const recentlyViewed = localStorage.getItem('recentlyViewed') || '[]';
      const viewedProducts = JSON.parse(recentlyViewed);
  
      // Make sure the product ID is not already in the array
      if (!viewedProducts.includes(productId)) {
        viewedProducts.unshift(productId);
      }
  
      // Limit the number of viewed products if needed
      if (viewedProducts.length > 10) {
        viewedProducts.pop(); // Remove the last viewed product if the array exceeds a certain size
      }
  
      localStorage.setItem('recentlyViewed', JSON.stringify(viewedProducts));
    } catch (error) {
      // Handle any errors that may occur during the process
      console.error('Error viewing product:', error);
    }
  };
  
  useEffect(() => {
    // Retrieve the recently viewed products from local storage
    const recentlyViewed = localStorage.getItem('recentlyViewed') || '[]';
    const viewedProducts = JSON.parse(recentlyViewed);
  
    // Now you can use viewedProducts in your component
  }, []);
  

  return (
    <>
      {data?.map((item, index) => {
        // Calculate the percentage difference between the previous and current price
        const percentageDifference = Math.round(
          ((item.previousPrice - item.price) / item.previousPrice) * 100
        );

        return (
          <div
            key={index}
            className={`${
              location.pathname === "/product" ? `gr-${grid}` : "col-3 jersey-col"
            }`}
          >
            <Link
            onClick={() => handleProductView(item?._id)}

              to={getProductLink(item)}
              className="product-card position-relative"
              style={{ width: grid === 12 ? "100%" : "" }}
            >
              <div className="product-image">
                <img
                  src={item?.images[0].url}
                  className="img-fluid mx-auto"
                  alt="product image"
                  width={160}
                />
              </div>
              <div className="percentage-difference">
                -{percentageDifference}%
              </div>
              <div className="product-details">
                <h6 className="brand">{item?.category}</h6>
                <h5 className="product-title">{item?.title}</h5>
                <p
                  className={`description ${
                    grid === 12 ? "d-block" : "d-none"
                  }`}
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                ></p>
                <p className="price">KES {item?.price.toLocaleString()}</p>
                <p className="price previous-price">
                  KES {item?.previousPrice.toLocaleString()}
                </p>
              </div>
              <div className="action-bar position-absolute"></div>
            </Link>
          </div>
        );
      })}
    </>
  );
};

export default Productcard2;

// Function to generate the product link based on the product type
function getProductLink(product) {
  if (product.type === "jersey") {
    return "/product2/" + product._id;
  } else if (product.type === "wines") {
    return "/winesproduct/" + product._id;
  } else {
    return "/product/" + product._id;
  }
}
