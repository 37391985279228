import React, { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import Container from "../components/Container";
import { services } from "../utils/Data";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import  {  getAllProducts } from "../features/products/productSlice";
import {  Link, useNavigate } from "react-router-dom";
import { addToWishlist } from "../features/products/productSlice";
import { useMediaQuery } from "react-responsive";
import "react-multi-carousel/lib/styles.css";
import { getAllCovers } from "../features/cover/coverSlice";
import { useParams } from 'react-router-dom';
import { getAllWines } from "../features/wines/wineSlice";
import Meta from "../components/Meta";
import { MdOutlineNavigateNext } from "react-icons/md";

function countWords(text) {
  return text.split(/\s+/).filter(word => word !== '').length;
}

const Home = () => {
  const [grid, setGrid] = useState(4);
  const productState = useSelector((state) => state.product.product);
  const navigate=useNavigate()
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isTitleTooLong = productState?.some(item => countWords(item?.title) > 10);
  const coverState = useSelector((state) => state.covers.covers);
  const recentlyViewed = useSelector((state) => state.product.recentlyViewed);

const { productId } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    getblogs();
    getallProducts();
    getallCovers();
    getAllWines();
  }, []);
  const getblogs = () => {
    dispatch(getAllBlogs());
  };
  const getallProducts = () => {
    dispatch(getAllProducts());
  };

  const getallCovers =()=>{
    dispatch(getAllCovers());
  }

  const addToWish = (id) => {
    dispatch(addToWishlist(id));
  };


  


  return (
    <>
      <Meta title={"Office Furniture"} />

<hr/>

<Container class1="home-wrapper-1 py-2" >
        <div className="d-flex top-cover-wrapper" >
        <div className='top-cover-1'>
            <div className="cover-overlay"></div>
            <img
              src="https://www.alloffice.co.za/wp-content/uploads/2014/02/Impressions12.jpg"
              alt=""
            />
            <div className="cover-content">
              <h1>Reception Desks</h1>
              <Link to="/reception-desk" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
          <div className='top-cover-1'>
          <div className="cover-overlay"></div>
            <img
              src="https://centrepointfurniture.co.ke/wp-content/uploads/2021/12/78k.jpeg"
              alt=""
            />
            <div className="cover-content">
              <h1>Office Sofas</h1>
              <Link to="/office-sofa" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>

        </div>
      </Container>

      <hr/>

      <Container class1="home-wrapper-2 service-wrapper">
  <div className="row">
    <div className="col-12">
      <div className="servies d-flex align-items-center justify-content-between">
        <Marquee>
          {services?.map((i, j) => {
            return (
              <div
                className="services-card d-flex align-items-center gap-15"
                key={j}
              >
                {typeof i.image === "string" ? (
                  <img src={i.image} alt="services" />
                ) : (
                  i.image
                )}
                <div className="services-content">
                  <h6>{i.title}</h6>
                  <p className="mb-0">{i.tagline}</p>
                </div>
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  </div>
</Container>

<hr/>

<Container class1="home-wrapper-1 py-2" >
        <div className="d-flex cover-wrapper" >
        <div className='cover'>
            <div className="cover-overlay"></div>
            <img
              src="https://ke.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/62/6433621/1.jpg?6835"
              alt=""
            />
            <div className="cover-content">
              <h1>Office Chairs</h1>
              <Link to="/office-chair" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
          <div className='cover'>
          <div className="cover-overlay"></div>
            <img
              src="https://pramukhfurniture.com/wp-content/uploads/2024/06/coffee-round-table.png"
              alt=""
            />
            <div className="cover-content-2">
              <h1>Coffee Tables</h1>
              <Link to="/coffee-table" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
          <div className='cover'>
          <div className="cover-overlay"></div>
            <img
              src="https://assets2.razerzone.com/images/pnx.assets/2831350804ce9a5dd9ba3b01a4c88791/500x500-productlines-enki.webp"
              alt=""
            />
            <div className="cover-content">
              <h1>Gaming Chairs</h1>
              <Link to="/gaming-chair" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
        </div>
      </Container>
 

      <Container class1="home-wrapper-1 py-2">
        <div className=" d-flex cover-wrapper section-3" >
        <div className='cover'
        
        >
        <div className="cover-overlay"></div>
        <img src="https://assets.wfcdn.com/im/13494940/resize-h700-w700%5Ecompr-r85/1014/101409185/Stenberg+Bookcase.jpg" alt="" />
        <div className="cover-content-2">
         <h1 
        >Bookshelves</h1>
         <Link to="/bookshelf" className="cover-button-2">Shop Now  </Link>
       </div>
        </div>
        <div className='cover'>
        <div className="cover-overlay"></div>
        <img src="https://www.furnituredirect.com.my/wp-content/uploads/2023/09/MH9-2.jpg" alt="" />
        <div className="cover-content-2">
         <h1 >Hanger</h1>
         <Link to="/hanger" className="cover-button-2">Shop Now  </Link>
       </div>
        </div>

        <div className='cover'>
        <div className="cover-overlay"></div>
        <img src="https://pramukhfurniture.com/wp-content/uploads/2024/08/Screenshot-2024-08-09-162513.png" alt="" />
        <div className="cover-content-2">
         <h1 >Fireproof safes</h1>
         <Link to="/fireproof-safe" className="cover-button-2">Shop Now  </Link>
       </div>
        </div>
        <div className='cover'
        
        >
        <div className="cover-overlay"></div>
         <img src="https://assets.wfcdn.com/im/43106091/resize-h700-w700%5Ecompr-r85/2553/255326810/Avrie+2+Door+Storage+Cabinet.jpg" alt="" />
         <div className="cover-content-2">
         <h1 >Office Cabinet</h1>
         <Link to="/office-cabinet" className="cover-button-2">Shop Now  </Link>
       </div>
        </div>

        </div>


 

      </Container>





      <Container class1="home-wrapper-1 py-2">
        <div className=" d-flex cover-wrapper" >
 
        <div className='cover'
        
        >
        <div className="cover-overlay"></div>
        <img src="https://furniturechoicekenya.com/wp-content/uploads/2023/11/Hfd1b7ed3828a4785930e7fa1a3105315o.jpg_720x720q50.webp" alt="" />
        <div className="cover-content-2">
         <h1 
        >Waiting Benches</h1>
         <Link to="/waiting-bench" className="cover-button-2">Shop Now  </Link>
       </div>
        </div>
        <div className='cover'
        
        >
        <div className="cover-overlay"></div>
         <img src="https://neilanfurniture.com/wp-content/uploads/2021/12/WhatsApp-Image-2023-02-17-at-09.03.27-1.jpeg" alt="" />
         <div className="cover-content-2">
         <h1 >Executive Desks</h1>
         <Link to="/executive-desk" className="cover-button-2">Shop Now  </Link>
       </div>
        </div>
  

        <div className='cover'>
        <div className="cover-overlay"></div>
        <img src="https://furniturechoicekenya.com/wp-content/uploads/2024/01/H5b2bc1a04a2746918d3a58c9f6d45d60l.jpg_960x960.webp" alt="" />
        <div className="cover-content-2">
         <h1 >Waiting Chairs</h1>
         <Link to="/waiting-chair" className="cover-button-2">Shop Now  </Link>
       </div>
        </div>
        </div>


 

      </Container>

      <Container class1="home-wrapper-1 last-section py-2" >
        <div className="d-flex section-6" >
        <div className="single-cover">
        <div className='cover'>
            <div className="cover-overlay"></div>
            <img
              src="https://centrepointfurniture.co.ke/wp-content/uploads/2024/08/1200x1200-75.jpg"
              alt=""
            />
            <div className="cover-content">
              <h1>Work Stations</h1>
              <Link to="/workstation" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
        </div>
          <div className="other-covers">
          <div className='cover'>
          <div className="cover-overlay"></div>
            <img
              src="https://furniturepalacekenya.com/wp-content/uploads/2021/12/0O2A4026-800x533.jpg"
              alt=""
            />
            <div className="cover-content">
              <h1>Office Desks</h1>
              <Link to="/office-desk" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
          <div className='cover'>
          <div className="cover-overlay"></div>
            <img
              src="https://furniturechoicekenya.com/wp-content/uploads/2023/06/H1df601b419b74034ba71091253a67d16M.jpg_960x960.webp"
              alt=""
            />
            <div className="cover-content">
              <h1>Boardroom Tables</h1>
              <Link to="/boardroom-table" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
          </div>
 
        </div>
      </Container>
      
      <hr/>




    </>
  );
};

export default Home;
